import Misc from './misc';

(function($) {
	
	$(document).ready(function(){
		if (!$('.hero-wrapper--home select')) {
			return false;
		}

		
		
		$('#care-facility').select2({
			placeholder: 'Find a Care Facility',
			minimumResultsForSearch: -1,
			dropdownCssClass: 'select2-home'
		});

		$('#retirement-village').select2({
			placeholder: 'Find a Retirement Village',
			minimumResultsForSearch: -1,
			dropdownCssClass: 'select2-home'
		});
	});

	$('.hero-wrapper--plus').on('click', function(event) {
		event.preventDefault();
		let scrollTop = 0;
		const offsetTop = $('.hero-wrapper--home').offset().top + $('.hero-wrapper--home').height();
		const headerHeight = $('.header-wrapper').outerHeight();

		if (Misc.getViewport().width > Misc.getMediaQueries()['mobilemenu-layout']) {
			scrollTop = offsetTop - headerHeight;
		} else {
			scrollTop = offsetTop;
		}

		$([document.documentElement, document.body]).animate({
			scrollTop: scrollTop
		}, 600);
	});

})( jQuery );
